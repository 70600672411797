import IBlog from "./Interfaces/IBlog";

const blogPosts: Array<IBlog> = [
  {
    oldId: "dwOJnBtXYAHosZyvzpHC",
    id: "just-started-my-angular-website",
    title: "Just started my website with Angular2+",
    description:
      "As a fun project I have built an <i>Angular Bootstrap</i> website hosted at <i>Google Cloud</i> with <i>Material Design</i>. My plan is to share mainly professional articles about programming, web development and cloud related topics.<br/><br/> Additionally posts might appear about fun facts or pictures, descreptive things about my hiking trips. The site is still under construction, I might need time to fill up with content, please be patient and more to come.",
    createdBy: "Norbert Szabó",
    hashtags: ["welcome", "website", "angular", "bootstrap", "material"],
    category: "Angular",
    image: "assets/website02_small.jpg",
    order: 1,
    status: "active",
    timestamp: "02/09/2019 3:46AM",
    icon: "Web",
    theme: "original",
  },
  {
    oldId: "react-redux-fake-data-store-with-hooks",
    id: "react-redux-fake-data-store-with-hooks",
    title: "React Redux fake data store with hooks",
    description:
      'Hooks are pretty new and hot topic in React world nowadays. The reason why this project has been created by me yesterday is to represent React hooks with <app-code-xs>useSelector</app-code-xs> and <app-code-xs>useDispatch</app-code-xs> usage in a functional component with simple Redux store. The app simulates a fake API call which on purpose returns sometimes with success or failed response.<br/><br/>The question why and how should I use these new hooks in a functional component with a Redux store is fairly frequently raised. Just like on Stack Overflow there was a confusion how to create a data table with a loading indicator with these hooks, find <a href="https://stackoverflow.com/questions/58780909/where-is-a-good-to-call-actions-for-fail-and-success-in-react-redux/58781346#58781346" target="_blank" rel="noopener noreferrer">my answer here</a>.<br/><br/>I guess it is much better to have a code example for explanation in this scenario. The above mentioned two hooks are the replication in functional components for <app-code-xs>mapStateToProps</app-code-xs> and <app-code-xs>mapDispatchToProps</app-code-xs> from class based components.<br/><br/>Follow the link to see the working example in my <a href="https://github.com/norbitrial/react-redux-loading-data-example" target="_blank" rel="noopener noreferrer">GitHub</a> repository.',
    createdBy: "Norbert Szabó",
    hashtags: [
      "react",
      "redux",
      "hooks",
      "usedispatch",
      "useselector",
      "mapstatetoprops",
      "mapdispatchtoprops",
      "github",
    ],
    category: "React, Redux",
    image: "assets/react-redux-data-store.jpg",
    order: 2,
    status: "active",
    timestamp: "11/10/2019 12:52PM",
    icon: "Storage",
    theme: "original",
  },
  {
    oldId: "fully-rebuilt-my-website-with-react",
    id: "fully-rebuilt-my-website-with-react",
    title: "Fully rebuilt my website with React ❤️",
    description: `Made the decision few weeks ago to rebuild my website from scratch with React TypeScript and Material-UI. Additional interesting part was using Travis CI for building and publishing to a Firebase project in Google Cloud. The page has not just been rebuilt but got a totally new design and look and feel. 🎨
            <br/><br/>
            There are quite a couple of changes from the last version. There is a new <a href="/blog">Blog</a> menu item where my thoughts, projects and technical topics are posted. And finally finished the <a href="/about">About</a> and the <a href="/contact">Contact</a> sections as well.
            <br/><br/>
            Now I can focus on a new project which might be the Tetris game what I planned earlier this year. 🎮 It would be pretty nice to build with React Native. 👾
            <br/><br/>
            If you would like to send me feedback how you like the new design, please find my contact information under <a href="/contact">Contact</a> page. 📝`,
    createdBy: "Norbert Szabó",
    hashtags: ["react", "webdevelopment", "frontend"],
    category: "Website",
    image: "assets/rebuilt-website01_small.jpg",
    order: 3,
    status: "active",
    timestamp: "11/18/2019 9:51PM",
    icon: "Build",
    theme: "original",
  },
  {
    oldId: "my-first-game-created-with-phaser3",
    id: "my-first-game-created-with-phaser3",
    title: "My first game created with Phaser3",
    description: `Game development was always one of my biggest interest but I have never been at a point to really start building one. As the winter vacation days started finally I had time to jump into something serious. 🎮
            <br/><br/>
            In the first few days I was looking for frameworks, engines and how to start guides. It was pretty entertaining watching videos on YouTube about Pixel Art, beautiful grapics, how people are making so called sprites to animate characters. So I have ended up with a decision to start making a platform style game for web.
            <br/><br/>
            So the decision was made, platform game, right? Started to dig into documentations, articles and I have found <b>Phaser3</b> which looked like easy to use framework. Additionally it has out of the box so called <b>arcade physics</b> which was exactly the thing what my idea just needed. ⚛️
            <br/><br/>
            The whole application development was taking approximatly 7-10 days all together where the programming language was <code>TypeScript</code>. Once I was leveraging the power of community where I got stuck with cleaning up scenes from memory, so here is the question on Stack Overflow: <a href="https://stackoverflow.com/questions/59439790/switching-between-scenes-in-phaser3" target="_blank">Switching between scenes in Phaser3</a>
            <br/><br/>
            Let's take a try and beat the others' best time with my first ever game here: <b><a href="https://feed-the-turtle.szabonorbert.me" target="_blank">Feed The Turtle 🐢</a></b> game.
            <br/><br/>
            The application currently does not support mobile unfortunately but it works like charm on desktop's browser. 💻 It was a really cool journey, I learned a lot from making a game from scratch. Biggest part was desining not just the application code as before but drawing pixel by pixel all the terrain, background and character animations.
            <br/><br/>
            If you ask me I would definitelly start again, every minute of development was fun 😎 and I am really proud of the end result. Let me know how you like it by contacting me on any of the platforms <a href="/contact">here</a> on my site.`,
    createdBy: "Norbert Szabó",
    hashtags: ["game", "phaser3", "typescript", "webdevelopment"],
    category: "Game",
    image: "assets/feed-the-turtle-screenshot.png",
    order: 4,
    status: "active",
    timestamp: "12/29/2019 9:58PM",
    icon: "Motorcycle",
    theme: "original",
  },
  {
    oldId: "earned-microsoft-az-900-certification",
    id: "earned-microsoft-az-900-certification",
    title: "Earned Microsoft AZ-900 Certifaction",
    description: `
            Few years ago I was looking for some kind of extra challenge where I can learn new technologies and additionally prove, verify my knowledge on that specific area.
            That's how I have ended up with Microsoft certifications.
            <br><br>
            Around 2014 summer I successfully passed my first ever certification which was <b>Programming in HTML5 with JavaScript and CSS3</b> (<a href="https://www.microsoft.com/en-us/learning/exam-70-480.aspx" rel="noreferrer noopener">70-480</a>). ✔️
            After passing the mentioned one I felt motivated so followed the suggested learning path till achieving <b>Microsoft Certified Solutions Developer: Web Applications</b>.
            Later after a successful year time slot with passed 5 exams for some reason I stopped collecting different certifications. So last year I decided 2020 will be somehow different.
            <br><br>
            For yesterday I registered myself and <i>successfully passed</i> 📜🏆 <b>Microsoft Azure Fundamentals</b> (<a href="https://docs.microsoft.com/en-us/learn/certifications/exams/az-900" rel="noreferrer noopener">AZ-900</a>) just to verify my knowledge with Microsoft Azure basics.
            On daily basis working with Azure is pretty fun but I want to demonstrate my skills somehow and I think this is a good beginning.
            The next goal to achieve which will be definitely more challenging exam is <b>Developing Solutions for Microsoft Azure</b> (<a href="https://docs.microsoft.com/en-us/learn/certifications/exams/az-203" rel="noreferrer noopener">AZ-203</a>) .
            <br><br>
            And no stop till <b>Microsoft Certified: Azure Solutions Architect Expert</b> title... 😜
        `,
    createdBy: "Norbert Szabó",
    hashtags: ["microsoft", "azure", "certification", "az-900"],
    category: "Game",
    image: "assets/microsoft-azure-az-900-cert_small.png",
    order: 5,
    status: "active",
    timestamp: "01/30/2020 10:15PM",
    icon: "VerifiedUser",
    theme: "original",
  },
  {
    oldId: "passed-microsoft-az-203-certification",
    id: "passed-microsoft-az-203-certification",
    title: "Passed Microsoft AZ-203 Certifaction",
    description: `
            As earlier I mentioned in <a href="/blog/earned-microsoft-az-900-certification" rel="noreferrer noopener">my previous blog post</a> I challenged myself for further certifications. After a long learning journey at end of Februray I <i>passed successfully</i> the <b>Developing Solutions for Microsoft Azure</b> (<a href="https://docs.microsoft.com/en-us/learn/certifications/exams/az-203" rel="noreferrer noopener">AZ-203</a>) certification. 🎉
            <br><br>
            As far as I can tell this exam is pretty challenging, requires hands-on experience and last but not least lots of prepration. 📚 Delivering excellent cloud based solutions need deeper understanding of the services what Microsoft provides for us, the developers. In the prepartion steps I raised different questions on Stack Overflow. I really like that community because there is a benefit in asking questions also. (<a href="https://stackoverflow.com/questions/60430965/querying-partitionkey-and-rowkey-syntax-in-azure-table-storage-service" rel="noreferrer noopener">Querying PartitionKey and RowKey syntax in Azure Table Storage service</a>, <a href="https://stackoverflow.com/questions/60553345/change-existing-azure-functions-hosting-os-from-windows-to-linux" rel="noreferrer noopener">Change existing Azure Function's hosting OS from Windows to Linux</a>) ❓🤔
            <br><br>
            Overall I am really happy to finished successfully this exam, now I can focus on the architecting part of Azure. As always the key is the continous learning, trying to help others with the gained knowledge. 🏫🧠
            <br><br>
            The aim is still <b>Microsoft Certified: Azure Solutions Architect Expert</b> title which <a href="https://docs.microsoft.com/en-us/learn/certifications/azure-solutions-architect" rel="noreferrer noopener">requires</a> two additional exams. Happy to learn! 📙📖😄
        `,
    createdBy: "Norbert Szabó",
    hashtags: ["microsoft", "azure", "certification", "az-203"],
    category: "Game",
    image: "assets/microsoft-azure-az-203-cert_small.png",
    order: 6,
    status: "active",
    timestamp: "03/08/2020 7:55PM",
    icon: "CloudDone",
    theme: "original",
  },
  {
    oldId: "lessons-learned-from-10k-stackoverflow",
    id: "lessons-learned-from-10k-stackoverflow",
    title: "Lessons learned from 10k StackOverflow reputation",
    description: `
            There are few questions for today regarding my StackOverflow reputations. The first and main one is <strong>why am I spending hours</strong> almost day by day to solve problems on StackOverflow?
            So <strong>10k</strong> reputation points sound a lot. It is a decent amount for sure which requires <i>time</i>, <i>patience</i> and last but not least <i>good analytical skills</i> for sure. [points;📈](emoji)
            <br><br>
            [/assets/stackoverflow10k.png;10k reputation image](image)
            <br>
            Probably it's also interesting how I reached that much of reputations over the last year? Are those reputations just points or are they giving something back to me?
            The easiest answer is this is <strong>a way faster learning method</strong> to see what other people are struggling with and trying to help them with a small context of the technical problem.
            The good part is both of the parties from a question standpoint can have benefits. The person who asked get an answer, the resolver practicing understanding different problems. To understand other's perspective, it requires <i>asking leading questions</i>, <i>reading documentations</i> which are definitely really valuable. [read;📚](emoji)[eye;👀](emoji)
            <h4>How I reached 10k reputation over the last year?</h4>
            The first thing when you start answering questions is you realize there is a [https://stackoverflow.com;home page](link) on StackOverflow where mainly <i>the new questions are living</i>. [interest;🤓](emoji) In fact you can choose topics or tags what you are interested in or what you want to solve.
            On a possibly resolved question each and every <strong>upvotes</strong> by other users <i>rewarded</i> with <strong>+10</strong> and the accepted ones with <strong>+15</strong> points.
            <br><br>
            After a while it turned out I can easily answer few of the questions where the increasing reputation points encouraged me to move further. Firstly, it just seems a silly game but it boosts to try to answer <i>much faster</i> and <i>more accurately</i>. The reason is the wrong answer might end up with downvotes which are awarded with <strong>-2</strong> points.
            <br><br>
            Because since the beginning of my career I was interested in web topics thus in the first couple of months I was focusing mainly on <strong>JavaScript</strong> questions.
            It became kind of a daily routine to <strong>look for not resolved questions</strong>. So the reward was great, I enjoyed a lot, was reading huge amount of documentations, made quotes from them with linked resources.
            In the last few years I have been <strong>working with React</strong> I decided to take a shot there as well. Currently I own <i>296</i> upvotes for that topic only which means <strong>2960 points</strong>.
            There are different awards like [https://stackoverflow.com/help/badges;badges](link), e.g.: in a topic bronze badge for <i>100</i> [bronze;🥉](emoji), silver badge for <i>400</i> [silver;🥈](emoji) and gold badge for <i>1000</i> [gold;🥇](emoji) upvotes.
            <br><br>
            [/assets/stackoverflow_react_score.png;react score](image)
            <br>
            In several cases it is not that easy to solve a problem but the good part is to have fun. [fun;🥳](emoji) My favorite answers which I enjoyed the most dealing with:
            <ul>
              <li>My highest voted answer is on the question [https://stackoverflow.com/questions/58049491/how-to-wait-until-speech-is-finished-inside-loop/58049676#58049676;How to wait until speech is finished inside Loop?](link) which relates to [https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesisUtterance;SpeechSynthesisUtterance](link) API,</li>
              <li>Sometimes in order to have a good or nice answer it just takes time to read carefully the documentation of a library or technology for example in the case of [https://stackoverflow.com/questions/61695148/switching-between-dark-and-light-mode-in-react/61695185#61695185;Switching between Dark and Light Mode in React](link),</li>
              <li>Also some cases it takes precious time to solve a problem e.g.: [https://stackoverflow.com/questions/59546928/keydown-up-events-with-react-hooks-not-working-properly/59554065#59554065;Keydown/up events with React Hooks not working properly](link),</li>
              <li>I really liked to explain [https://stackoverflow.com/questions/59611412/how-does-action-reducer-chain-work-in-react-redux/59611468#59611468;How does action-reducer chain work in react-redux](link) one with a quick paint illustration [smile;😃](emoji).</li>
            </ul>
            What I also learned while doing this daily routine is the <strong>highest voted solutions</strong> are the <strong>generic ones</strong>. By this I mean when you try to explain a common scenario instead of resolving some random problem in someone's code for example [https://stackoverflow.com/questions/58027960/is-it-enough-to-use-provider-one-time/58028032#58028032;Is it enough to use Provider one time?](link) or [https://stackoverflow.com/questions/55430839/what-should-the-rel-be-for-this-javascript/55430861#55430861;What should the “rel” be for this JavaScript?](link).
            <br><br>
            <strong>+1:</strong> Meanwhile resolving problems in <strong>JavaScript</strong> and <strong>array</strong> topics the [https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce;.reduce()](link) became my favorite array function.
            <h4>Asking question won't make you look dumb</h4>
            Asking question is not bad at all. It helps you <strong>think you through the problem</strong>. Meanwhile describing the issue it <i>might get clearer</i> for you where the original confusion was.
            Don't be afraid to ask a question but before make sure you <i>did your research</i>, explain <i>what you have tried</i> so far and where your confusion is. It's a much better way to ask a different person than expecting Google searches from someone else. [search;🔍](emoji)
            <br><br>
            If we check the ratio of [https://stackoverflow.com/users/7599510/norbitrial?tab=topactivity;my posts on StackOverflow](link) I have way more answers <b>[517]</b> than questions <b>[20]</b>.
            But this doesn't mean I'm not asking questions there. Sometimes I solve them for myself. [smile;😃](emoji) Let me highlight few of my favorite ones by writing this blog post:
            <ul>
              <li>[https://stackoverflow.com/questions/59431371/use-emoji-as-favicon-in-websites;Use emoji as favicon in websites](link),</li>
              <li>[https://stackoverflow.com/questions/59779011/get-access-tokens-with-react-aad-msal-for-two-different-resources-and-scopes;Get access tokens with react-aad-msal for two different resources and scopes](link),</li>
              <li>[https://stackoverflow.com/questions/60430965/querying-partitionkey-and-rowkey-syntax-in-azure-table-storage-service;Querying PartitionKey and RowKey syntax in Azure Table Storage service](link),</li>
              <li>[https://stackoverflow.com/questions/60300400/file-pattern-for-publish-pipeline-artifact-in-azure-devops;File pattern for Publish Pipeline Artifact in Azure DevOps](link) - I resolved for myself. [tick;✔️](emoji)</li>
            </ul>
            There was a time when I was learning for <i>[/blog/passed-microsoft-az-203-certification;Developing Solutions for Microsoft Azure](link)</i> exam which I passed successfully. At that time I was reading lots of Microsoft documentations and had questions what I raised on StackOverflow.
            Asking <strong>good</strong> or <strong>popular questions</strong> are rewarded nicely and can increase your reputations as well.
            <h4>Wrap up StackOverflow topic</h4>
            In summary here are in bullet points what this time investment was offering to me:
            <ul>
              <li>Learn a lot and have better understanding on different technologies by <strong>reading lots of documentations</strong> on [https://developer.mozilla.org/en-US/;MDN](link),</li>
              <li>Way <strong>faster learning</strong> method than only doing coding alone, not reviewed by other developers,</li>
              <li>Giving confidence, also it helps to <strong>get rid of the fear to ask</strong> questions in <i>public</i>.</li>
            </ul>
            It's easier to get reputations by answering questions than you think, the hardest part is <strong>start</strong> doing that as always. Have fun and thanks for reading! [fun;🎉](emoji)
        `,
    createdBy: "Norbert Szabó",
    hashtags: ["stackoverflow", "reputation", "lessonslearned", "10k"],
    category: "Game",
    image: "assets/stackoverflowlogo.jpg",
    order: 7,
    status: "active",
    timestamp: "09/18/2020 10:53PM",
    icon: "EmojiEvents",
    theme: "new",
  },
];

export default {
  WebsiteUrl: "https://szabonorbert.me",
  AppLogo: "assets/logo_small.jpg",
  HomeTitle: "Home",
  BlogTitle: "Blog",
  ContactTitle: "Contact",
  AboutTitle: "About",
  HomeDescription:
    "👽 Norbert Szabó's WebSite | Tech Blog of a Web Developer <br> <br> Tech blog of an enthusiastic web developer who desperately loves chocolate 🍫. Find here articles mainly about web development, my side projects and hobbies.",
  BlogDescription:
    "Find articles mainly about programming, web development and my hobbies",
  ContactDescription:
    "Are looking for contact information? Drop me an e-mail or find my contact info here",
  AboutDescription:
    "Do you have other questions about me? Feel free to check this introduction page",
  BlogImage: "assets/menu_blog_small.jpg",
  ContactImage: "assets/menu_contact_small.jpg",
  AboutImage: "assets/menu_about_small.jpg",
  HeaderGradientTopColor: "#3f51b5",
  HeaderGradientBottomColor: "#0078f1",
  FooterColor: "#f5f5f5",
  TextColor: "#9e9e9e",
  LinkGithub: "https://github.com/norbitrial",
  LinkStackoverflow:
    "https://stackoverflow.com/users/7599510/norbitrial?tab=profile",
  LinkLinkedIn: "https://www.linkedin.com/in/norbertszabo1988",
  LinkInstagram: "https://instagram.com/norbitrial",
  LinkFacebook: "https://facebook.com/norbitrial",
  LinkEmail:
    "mailto:norbitrial8@gmail.com?subject=Contacting from szabonorbert.me",
  BoxShadow:
    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  BlogPosts: blogPosts,
  WebSiteTitle: `Norbert Szabó's website`,
  FooterHeight: "40px",
  CopySuccessMessage: "Link copied to clipboard!",
  GATrackingId: "UA-153402604-1",
};
