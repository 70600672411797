import types from './types';

const initialState = {
    currentMenu: 'home',
};

export default (state=initialState, action:any) => {
    switch(action.type) {
        case types.UPDATE_CURRENT_MENU_ASYNC:
            const {currentMenu} = action;

            return {
                ...state,
                currentMenu,
            };
        default:
            return state;
    }
}