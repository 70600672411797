import types from './types';
import { takeEvery, put } from 'redux-saga/effects';
import ReactGA from 'react-ga';
import Consts from '../../Consts';

function* updateCurrentMenu(action:any) {
    const {currentMenu} = action;
    yield put({type: types.UPDATE_CURRENT_MENU_ASYNC, currentMenu});

    const urlToTrack = window.location.href;

    if (window.location.host.indexOf('localhost') !== -1) {
        console.log('On local no GA tracking', {urlToTrack, currentMenu, GATrackingId: Consts.GATrackingId});
    } else {
        ReactGA.pageview(urlToTrack);
    }
}

export default function* navigationSaga() {
    yield takeEvery(types.UPDATE_CURRENT_MENU, updateCurrentMenu);
}