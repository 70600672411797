import { combineReducers } from "redux";
import navigationReducer from './Navigation/reducer';
import navigationSaga from './Navigation/saga';
import { fork } from 'redux-saga/effects';

const reducers = combineReducers({navigationReducer});
const sagas = function*() {
    yield fork(navigationSaga);
};

export default {
    reducers,
    sagas,
}