import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { unregister } from "./serviceWorker";
import appStore from "./Store";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import Loader from "./Components/Loader";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";

const App = lazy(() => import("./App"));
const sagaMiddleware = createSagaMiddleware();
const store = createStore(appStore.reducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(appStore.sagas);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="app-main-loader">
            <Loader />
          </div>
        }
      >
        <App />
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

unregister();
